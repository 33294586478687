// extracted by mini-css-extract-plugin
export var carouselContainer = "J_vj d_t d_F d_bc d_X";
export var carouselContainerCards = "J_vk J_vj d_t d_F d_bc d_X";
export var carouselContainerSides = "J_vl d_t d_F d_X";
export var prevCarouselItem = "J_vm d_t d_F d_Y d_h";
export var prevCarouselItemL = "J_vn J_vm d_t d_F d_Y d_h";
export var moveInFromLeft = "J_vp";
export var prevCarouselItemR = "J_vq J_vm d_t d_F d_Y d_h";
export var moveInFromRight = "J_vr";
export var selectedCarouselItem = "J_vs d_t d_F d_X d_bc";
export var selectedCarouselItemL = "J_vt J_vs d_t d_F d_X d_bc";
export var selectedCarouselItemR = "J_vv J_vs d_t d_F d_X d_bc";
export var nextCarouselItem = "J_vw d_t d_F d_Y d_h";
export var nextCarouselItemL = "J_vx J_vw d_t d_F d_Y d_h";
export var nextCarouselItemR = "J_vy J_vw d_t d_F d_Y d_h";
export var arrowContainer = "J_vz d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainer = "J_vB J_vz d_F d_Y d_dy d_bj d_2 d_bZ";
export var prevArrowContainerHidden = "J_vC J_vB J_vz d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var nextArrowContainer = "J_vD J_vz d_F d_Y d_dy d_bj d_2 d_bZ";
export var carouselContainerProducts = "J_vF";
export var nextArrowContainerHidden = "J_vG J_vD J_vz d_F d_Y d_dy d_bj d_2 d_bZ d_h";
export var arrow = "J_kD d_Y";
export var prevArrow = "J_vH J_kD d_Y";
export var nextArrow = "J_vJ J_kD d_Y";
export var carouselIndicatorContainer = "J_vK d_t d_dt d_bj d_bx d_bB d_bM d_bG d_Y";
export var btnsContainer = "J_vL d_t d_bx d_bC";
export var carouselText = "J_vM d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var carouselTextInactive = "J_vN J_vM d_dn d_bx d_bB d_bM d_bZ d_dy d_cB";
export var indicator = "J_vP d_b5";
export var carouselIndicator = "J_vQ J_vP d_b5";
export var carouselIndicatorSelected = "J_vR J_vP d_b5";
export var arrowsContainerTopRight = "J_vS d_Y d_bj d_bz";
export var arrowsContainerBottomLeft = "J_vT d_Y d_bj d_bz";
export var arrowsContainerSides = "J_vV d_Y d_bj d_bz";
export var smallArrowsBase = "J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainer = "J_vX J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrev = "J_vY J_vX J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerSmall = "J_vZ J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var smallArrowContainerPrevSmall = "J_v0 J_vZ J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB";
export var icon = "J_pY";
export var iconSmall = "J_v1";
export var multipleWrapper = "J_v2 d_bz d_bC d_bc";
export var multipleImage = "J_v3 d_bz";
export var sidesPrevContainer = "J_v4 J_vX J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";
export var sidesNextContainer = "J_v5 J_vX J_vW d_bR d_bx d_bB d_bM d_bZ d_dy d_cB d_fl d_Y d_bj d_cB";