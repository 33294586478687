// extracted by mini-css-extract-plugin
export var alignLeft = "s_p3 d_fm d_bD d_ds";
export var alignCenter = "s_bM d_fn d_bB d_dt";
export var alignRight = "s_p4 d_fp d_bF d_dv";
export var leftWrapper = "s_rm d_bx d_bB d_bG";
export var wrapper = "s_rn d_t d_b9";
export var imgWrapper = "s_rp";
export var caption = "s_rq";
export var text = "s_pZ d_cq";
export var instagramGuttersImage = "s_gZ d_gZ d_cs d_H d_cs";
export var instagramNoGuttersImage = "s_gY d_gY d_cB d_H d_cB";
export var instagramContainerFull = "s_rr d_dR d_t";
export var instagramContainer = "s_rs d_dT";
export var content = "s_rt d_b9";
export var errorContainer = "s_rv";
export var instagramImg = "s_rw d_t d_F d_dW d_bb d_Y d_6 d_5 d_2 d_7 d_bP";
export var instagramImageWrapper = "s_rx d_fd d_X";
export var instagramBtnWrapper = "s_g6 d_g6 d_Y d_bz d_bM d_bV d_dt d_bn";
export var instagramBtnWrapperLeft = "s_ry d_d0 d_dZ d_t d_bx d_bD d_c5";
export var instagramBtnWrapperCenter = "s_rz d_d1 d_dZ d_t d_bx d_bB d_c5";
export var instagramBtnWrapperRight = "s_rB d_d2 d_dZ d_t d_bx d_bF d_c5";