// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q3 d_gr d_cq";
export var heroHeaderCenter = "r_gs d_gs d_cq d_dt";
export var heroHeaderRight = "r_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "r_q4 d_gn d_ct";
export var heroParagraphCenter = "r_gp d_gp d_ct d_dt";
export var heroParagraphRight = "r_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "r_q5 d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "r_q6 d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "r_q7 d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "r_q8 d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "r_q9 d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "r_rb B_rb";
export var heroExceptionNormal = "r_rc B_rc";
export var heroExceptionLarge = "r_rd B_rd";
export var Title1Small = "r_rf B_rf B_sL B_sM";
export var Title1Normal = "r_rg B_rg B_sL B_sN";
export var Title1Large = "r_rh B_rh B_sL B_sP";
export var BodySmall = "r_rj B_rj B_sL B_s3";
export var BodyNormal = "r_rk B_rk B_sL B_s4";
export var BodyLarge = "r_rl B_rl B_sL B_s5";