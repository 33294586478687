// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_sx d_bB";
export var storyRowWrapper = "y_ht d_ht d_bH";
export var storyLeftWrapper = "y_sy d_bx d_bM";
export var storyWrapperFull = "y_sz d_cB";
export var storyWrapperFullLeft = "y_nL d_cB d_bx d_bM d_bB";
export var contentWrapper = "y_mS d_hv";
export var storyLeftWrapperCenter = "y_sB d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "y_sC d_hB";
export var storyHeader = "y_sD d_hz d_t d_cq";
export var storyHeaderCenter = "y_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "y_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "y_sF d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "y_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "y_sG d_fd d_X";
export var imageWrapperFull = "y_sH d_t d_F d_bc d_X";