// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qw d_fm d_bD d_ds";
export var alignLeft = "q_p3 d_fm d_bD d_ds";
export var alignDiscCenter = "q_qx d_fn d_bB d_dt";
export var alignCenter = "q_bM d_fn d_bB d_dt";
export var alignDiscRight = "q_qy d_fp d_bF d_dv";
export var alignRight = "q_p4 d_fp d_bF d_dv";
export var footerContainer = "q_qz d_dT d_bT";
export var footerContainerFull = "q_qB d_dR d_bT";
export var footerHeader = "q_kb d_kb";
export var footerTextWrapper = "q_qC d_t";
export var footerDisclaimerWrapper = "q_kj d_kj d_ch";
export var badgeWrapper = "q_qD d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "q_qF undefined";
export var footerDisclaimerLeft = "q_qG undefined";
export var verticalTop = "q_qH d_bx d_bG d_bL d_bJ";
export var firstWide = "q_qJ";
export var disclaimer = "q_qK d_bx d_bG";
export var socialDisclaimer = "q_qL";
export var left = "q_qM";
export var wide = "q_qN d_cw";
export var right = "q_qP d_bH";
export var line = "q_fh d_fj d_cs";
export var badgeDisclaimer = "q_qQ d_bz d_bM d_bG";
export var badgeContainer = "q_qR d_bx d_bF d_bM";
export var badge = "q_qS";
export var padding = "q_qT d_c5";
export var end = "q_qV d_bF";
export var linkWrapper = "q_qW d_dy";
export var link = "q_mW d_dy";
export var colWrapper = "q_qX d_cv";
export var consent = "q_f d_f d_bz d_bM";
export var media = "q_qY d_bv d_dw";
export var itemRight = "q_qZ";
export var itemLeft = "q_q0";
export var itemCenter = "q_q1";
export var exceptionWeight = "q_q2 B_tb";