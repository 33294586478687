// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "v_rY d_gP d_ct d_ds";
export var quoteParagraphCenter = "v_gQ d_gQ d_ct d_dt";
export var quoteParagraphRight = "v_gR d_gR d_ct d_dv";
export var quoteRowLeft = "v_rZ d_bD";
export var quoteRowCenter = "v_r0 d_bB";
export var quoteRowRight = "v_r1 d_bF";
export var quoteWrapper = "v_gM d_gM d_t d_cB d_bx d_bM d_bB";
export var quoteContentWrapper = "v_gN d_gN";
export var quoteExceptionSmall = "v_r2 B_r2";
export var quoteExceptionNormal = "v_r3 B_r3";
export var quoteExceptionLarge = "v_r4 B_r4";
export var quoteAuthorExceptionSmall = "v_r5 B_r5";
export var quoteAuthorExceptionNormal = "v_r6 B_r6";
export var quoteAuthorExceptionLarge = "v_r7 B_r7";