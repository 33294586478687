// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mK";
export var warningBackgrounddark = "g_mL";
export var warningBackgroundcustom = "g_mM";
export var bottom = "g_gD";
export var modal = "g_mN";
export var container = "g_mP";
export var btnWrapper = "g_d0 d_bB d_bM d_bG";
export var elementWrapper = "g_mQ d_bB d_bM";
export var titleWrapper = "g_mR d_bB d_bM";
export var contentWrapper = "g_mS";
export var cookieButtons = "g_mT d_bx d_bB d_bM d_bG";
export var btn = "g_mV d_bx d_bB d_bM";
export var link = "g_mW";
export var decline = "g_mX g_mV d_bx d_bB d_bM";
export var editor = "g_mY";
export var row = "g_mZ";